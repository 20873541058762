import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import configs from "../../../../configs/apiConfigs";
import {
  fetchClinicProfile,
  fetchConsultationsSlots,
  fetchConsultationType,
} from "../../../../services/dentist";
import { showAddress } from "../../components/ShowAddress/ShowAddress";
import "./css/style.css";
import { Avatar } from "@material-ui/core";
import { AppointmentScheduling } from "../../components/AppointmentScheduling/AppointmentScheduling";
import moment from "moment";
import { RatingComp } from "../../../RatingComponent";
import {
  loadMap,
  setGoogleMap,
  setMarkerToPostion,
} from "../../../../utils/googleFunction";
import { fetchReviews } from "../../../../services/results";
import { Helmet } from "react-helmet";
import { Accordion } from "react-bootstrap";
import Slider from "react-slick";
import HeaderWhite from "../HeaderWhite/HeaderWhite";

let googleMap;
export const ClinicProfileNew = () => {
  const params: any = useParams();
  const [clinicProfile, setClinicProfile]: any = useState(null);
  const [consultationTypes, setConsultationTypes]: any = useState(null);
  const [selectedConsultation, setSelectedConsultation] = useState("");
  const [slots, setSlots] = useState([]);
  const [firstAvailable, setFirstAvailable] = useState(null);
  const [reviews, setReviews]: any = useState(null);
  const [accordianActiveKey, setAccordianActiveKey] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [serviceExpanded, setServiceExpanded] = useState(false);
  const [featureExpanded, setFeatureExpanded] = useState(false);
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const history: any = useHistory();
  const [isShowHeader, setIsShowHeader] = useState(false);
  const [showReviews, setShowReviews] = useState(false);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.isInternal
    ) {
      setIsShowHeader(true);
    }
  }, [history.location.state]);
  const handleAccordianActiveKey = (eventKey: any) => {
    console.log("eventKey", eventKey);
    setAccordianActiveKey(eventKey);
  };
  useEffect(() => {
    callFetchClinicProfile();
    callFetchClinicReviews();
    loadMap().then(
      (success) => {},
      (err) => {
        console.log(err);
      }
    );
    window.scrollTo(0, 0);
  }, []);
  const callFetchClinicProfile = async () => {
    try {
      const response: any = await fetchClinicProfile(params.uniqueId);
      if (response.response.data.status.error === false) {
        const data = response.response.data;
        setClinicProfile(data);
        callFetchConsultationTypes(data);
        const latitude = parseFloat(data.address.latitude);
        const longitude = parseFloat(data.address.longitude);
        setGoogleMap({ latitude: latitude, longitude: longitude }, "map")
          ?.then((success: any) => {
            googleMap = success;
            setMarkerToPostion(googleMap, {
              latitude: latitude,
              longitude: longitude,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const callFetchConsultationTypes = async (clinicProfile: any) => {
    try {
      const response: any = await fetchConsultationType(
        params.uniqueId,
        "provider=default&location=false"
      );
      if (response.response.data.status.error === false) {
        setConsultationTypes(response.response.data);
        // setSelectedConsultation(response?.response?.data?.types?.[0]?.id);
        callSlots({
          doctorId: response?.response?.data?.defaultProvider?.id,
          clinicId: clinicProfile?.id,
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().add(3, "day").format("YYYY-MM-DD"),
          consultationTypeId: response?.response?.data?.types?.[0]?.id,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const callSlots = async (obj: any) => {
    try {
      console.log(obj);
      let _obj = {
        doctorId: consultationTypes?.defaultProvider?.id,
        clinicId: clinicProfile?.id,
        consultationTypeId: selectedConsultation,
        ...obj,
      };
      const response: any = await fetchConsultationsSlots({
        appointmentData: [_obj],
      });
      setSlots(response?.response?.data?.appointments ?? []);
      setFirstAvailable(response?.response?.data?.firstAvailable);
      console.log("response", response);
    } catch (err) {
      console.log("err", err);
    }
  };
  const callFetchClinicReviews = async () => {
    try {
      const response: any = await fetchReviews(params.uniqueId);
      console.log(response);
      setReviews(response?.response?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  let services: any[] = [];
  if (clinicProfile?.doctors?.length > 0) {
    clinicProfile?.doctors?.forEach((value: any) => {
      if (value.services && value.services.length > 0) {
        value.services.forEach((serviceParent: any) => {
          const findService = services.findIndex((service: any) => {
            return service.id.toString() === serviceParent.id.toString();
          });
          if (findService === -1) {
            services.push(serviceParent);
          }
        });
      }
    });
  }
  const MapLink = (lat: any, lng: any) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(url, "_blank");
  };
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    console.log("clinicProfile", clinicProfile);
  }, [clinicProfile]);
  return (
    <>
      <Helmet>
        <title>{clinicProfile?.seodata?.metaTitle}</title>
        <meta name="description" content={clinicProfile?.seodata?.metaDesc} />
        <meta name="keywords" content={clinicProfile?.seodata?.metaKeywords} />
      </Helmet>
      {isShowHeader && <HeaderWhite></HeaderWhite>}
      <main>
        <div className="main-grid-sec mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                {/* Mobile only */}
                <div className="d-sm-none d-md-none d-lg-none d-xl-none d-none mb-4">
                  <div className="about-dr justify-content-between align-items-center flex-wrap flex-md-nowrap">
                    <div className="d-flex profile align-items-center order-2 order-md-1">
                      <div
                        className="img-box me-3 text-center"
                        onClick={() => {
                          if (clinicProfile?.website) {
                            const url = `https://${clinicProfile?.website}`;
                            console.log("website", url);
                            window.open(url, "_blank");
                          }
                        }}
                      >
                        {clinicProfile?.logo?.name &&
                        clinicProfile?.logo.type ? (
                          <img
                            src={
                              configs.IMAGE_URL +
                              "clinic/" +
                              clinicProfile.logo.name +
                              "-200x200." +
                              clinicProfile.logo.type
                            }
                            alt=""
                            className="img-fluid"
                            width={"118px"}
                          />
                        ) : (
                          <img
                            src={configs.DEFAULT_IMAGE_URL_CLINIC}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div className="content-box">
                        <h4
                          onClick={() => {
                            if (clinicProfile?.website) {
                              const url = `https://${clinicProfile?.website}`;
                              console.log("website", url);
                              window.open(url, "_blank");
                            }
                          }}
                        >
                          {clinicProfile?.name}{" "}
                          <i className="bi bi-patch-check-fill ms-1 text-primary"></i>
                        </h4>
                        <div className="d-inline-flex align-items-center flex-wrap">
                          {" "}
                          {showAddress(clinicProfile?.address ?? "")}{" "}
                          {clinicProfile?.address &&
                            clinicProfile?.onlineSchedulingLocation && (
                              <a
                                onClick={() => {
                                  MapLink(
                                    clinicProfile?.address?.latitude,
                                    clinicProfile?.address?.longitude
                                  );
                                }}
                                className="btn btn-link p-0 ms-1"
                              >
                                Get direction
                              </a>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="practice-sidebar">
                  {consultationTypes?.types?.length > 0 && (
                    <AppointmentScheduling
                      handleConsultationTypeChange={(value: any, obj: any) => {
                        setSelectedConsultation(value);
                        callSlots(obj);
                      }}
                      selectedConsultation={selectedConsultation}
                      consultationTypes={consultationTypes}
                      slots={slots}
                      clinicName={clinicProfile?.name}
                      clinicId={clinicProfile?.id}
                      doctorId={consultationTypes?.defaultProvider?.id}
                      appointmentTypeId={
                        consultationTypes?.defaultProvider
                          ?.appointmentTypes?.[0]?.id ?? ""
                      }
                      clinicUniueId={params.uniqueId}
                      firstAvailable={firstAvailable}
                      flexibleSchedulingOn={true}
                    ></AppointmentScheduling>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="tab-view-practice">
                  <div id="tab-about" className="border-bottom tab-box">
                    <div className="about-dr d-sm-block d-md-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
                      <div className="d-flex profile align-items-center order-2 order-md-1">
                        <div
                          className="img-box me-3 text-center"
                          onClick={() => {
                            if (clinicProfile?.website) {
                              const url = `https://${clinicProfile?.website}`;
                              console.log("website", url);
                              window.open(url, "_blank");
                            }
                          }}
                        >
                          {clinicProfile?.logo?.name &&
                          clinicProfile?.logo.type ? (
                            <img
                              src={
                                configs.IMAGE_URL +
                                "clinic/" +
                                clinicProfile.logo.name +
                                "-200x200." +
                                clinicProfile.logo.type
                              }
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={configs.DEFAULT_IMAGE_URL_CLINIC}
                              alt=""
                              className="img-fluid"
                            />
                          )}
                        </div>
                        <div className="content-box">
                          <a
                            onClick={() => {
                              if (clinicProfile?.website) {
                                const url = `https://${clinicProfile?.website}`;
                                console.log("website", url);
                                window.open(url, "_blank");
                              }
                            }}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              padding: 0,
                            }}
                          >
                            <h4>
                              {clinicProfile?.name}{" "}
                              <i className="bi bi-patch-check-fill ms-1 text-primary"></i>
                            </h4>
                          </a>
                          <div className="d-inline-flex align-items-center flex-wrap">
                            {" "}
                            {showAddress(clinicProfile?.address ?? "")}{" "}
                            {clinicProfile?.address &&
                              clinicProfile?.onlineSchedulingLocation && (
                                <a
                                  onClick={() => {
                                    MapLink(
                                      clinicProfile?.address?.latitude,
                                      clinicProfile?.address?.longitude
                                    );
                                  }}
                                  className="btn btn-link p-0 ms-1"
                                >
                                  Get direction
                                </a>
                              )}
                          </div>
                        </div>
                      </div>
                      {clinicProfile?.onlineSchedulingReview && (
                        <div className="review-detail-box text-center order-1 order-md-2 mx-auto mx-md-0">
                          <div className="avg-rating">
                            <strong>{reviews?.rating?.avg}</strong>/5
                          </div>
                          <RatingComp
                            rating={reviews?.rating?.avg ?? 0}
                          ></RatingComp>
                          <span
                            className="no-of-review d-block"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowReviews(true);
                              if (document.getElementById("tab-reviews")) {
                                document
                                  .getElementById("tab-reviews")
                                  .scrollIntoView({ behavior: "smooth" });
                              }
                            }}
                          >
                            {reviews?.rating?.total} Reviews
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="more-pics mb-4">
                      <a
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        className="btn btn-link text-decoration-none px-0"
                      >
                        More photos
                      </a>
                    </div>
                    {/* <div className="top-review py-3 px-5 my-2">
                                            <p>{reviews?.reviews[0]?.reviewText}</p>
                                            <div style={{ fontWeight: 500 }}>
                                                {reviews?.reviews[0]?.FullName}
                                                <span>
                                                    <i className="bi bi-star-fill mx-1"></i>
                                                    {reviews?.reviews[0]?.rating}
                                                </span>
                                            </div>
                                        </div> */}
                    {clinicProfile?.onlineSchedulingAbout && (
                      <>
                        <div className="welcome-txt my-4 py-3">
                          <h4>Welcome to {clinicProfile?.name}</h4>
                          <div
                            className="d-inline clinic-desc-p"
                            dangerouslySetInnerHTML={{
                              __html: clinicProfile?.description ?? "",
                            }}
                          ></div>
                          {/* <a href="" className="btn btn-link p-0 ms-2">
                        read more
                      </a> */}
                        </div>
                        <Accordion
                          defaultActiveKey="0"
                          onSelect={handleAccordianActiveKey}
                        >
                          <div className="faq">
                            <div className="accordion" id="profile-faq">
                              {clinicProfile?.questionResponse.map(
                                (item: any, index: any) => {
                                  return (
                                    <div
                                      className="accordion-item border-0"
                                      key={index}
                                    >
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <Accordion.Toggle
                                          eventKey={item.id}
                                          className={
                                            accordianActiveKey === item.id
                                              ? "accordion-button p-0 bg-transparent border-0 "
                                              : "accordion-button p-0 bg-transparent border-0 collapsed"
                                          }
                                          as="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                        >
                                          {item.title}
                                        </Accordion.Toggle>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show "
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#profile-faq"
                                      >
                                        <div className="accordion-body px-0 pb-0">
                                          <Accordion.Collapse
                                            eventKey={item.id}
                                          >
                                            <p>{item.response}</p>
                                          </Accordion.Collapse>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                              {/* <div className="accordion-item border-0">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button p-0 bg-transparent border-0 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                What types of dental service do we offer?
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#profile-faq"
                            >
                              <div className="accordion-body px-0 pb-0">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo{" "}
                                  <a href="" className="btn btn-link p-0">
                                    read more
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item border-0">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                What are your hours of operation?
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#profile-faq"
                            >
                              <div className="accordion-body px-0 pb-0">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo{" "}
                                  <a href="" className="btn btn-link p-0">
                                    read more
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </Accordion>
                      </>
                    )}
                  </div>
                  {clinicProfile?.onlineSchedulingPracticePhoto && (
                    <div id="tab-photos" className="border-bottom tab-box">
                      <h4>Practice photos</h4>
                      {/* Need to intigrate slide */}
                      <div className="photo-slider">
                        <Slider {...settings}>
                          {clinicProfile?.photos?.map(
                            (photo: any, index: number) => {
                              if (
                                photo.image &&
                                photo.image.name &&
                                photo.image.type
                              ) {
                                return (
                                  <div className="img-box">
                                    <img
                                      src={
                                        configs.IMAGE_URL +
                                        "/clinic/" +
                                        photo.image.name +
                                        "-500x500." +
                                        photo.image.type
                                      }
                                      alt=""
                                    />
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
                </div>
                {/* {
                                    clinicProfile?.onlineSchedulingLogoBanner &&
                                    <div className="practice-profile d-none d-sm-none d-md-flex align-items-center">
                                        <div className="img-box d-flex align-items-center">
                                            {
                                                clinicProfile?.logo?.name && clinicProfile?.logo.type ?
                                                    <img src={configs.IMAGE_URL + 'clinic/' + clinicProfile.logo.name + '-200x200.' + clinicProfile.logo.type} alt="" className="img-fluid" /> :
                                                    <img src={configs.DEFAULT_IMAGE_URL_CLINIC} alt="" className="img-fluid" />
                                            }
                                        </div>
                                        <div className="content-box ms-4 ml-4">
                                            <div className="trusted-txt">
                                                <img src={require("./images/trusted-icon.svg")} className="mr-2" />Trusted by XcarePro
                                            </div>
                                            <div className="p-name">{clinicProfile?.name}</div>
                                            <div id="clinicAddress" className="p-address">
                                                {
                                                    showAddress(clinicProfile?.address ?? "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                } */}
                {/* <div className="tab-view-practice">
                                    {clinicProfile?.onlineSchedulingPageHeader &&
                                        <div className="tab-strip border-top border-bottom py-3 d-lg-flex d-none">
                                            {
                                                clinicProfile?.onlineSchedulingAbout &&
                                                <a href={`#tab-about`} className="mr-3">About</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingPracticePhoto &&
                                                <a href={`#tab-photos`} className="mr-3">Practice Photos</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingProviders &&
                                                <a href={`#tab-providers`} className="mr-3">Providers</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingReview &&
                                                <a href={`#tab-reviews`} className="mr-3">Reviews</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingFeatures &&
                                                <a href={`#tab-features`} className="mr-3">Features</a>
                                            }
                                            {
                                                clinicProfile?.onlineSchedulingServices &&
                                                <a href={`#tab-services`} className="mr-3">Services</a>
                                            }
                                        </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingAbout &&
                                        <div id="tab-about">
                                            <h3>Welcome to {clinicProfile?.name}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: clinicProfile?.description ?? "" }}></p>
                                        </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingPracticePhoto &&
                                        <div id="tab-photos">
                                            <h3>Practice Photos</h3>
                                            <div className="d-flex practice-pics justify-content-between">
                                                {
                                                    clinicProfile?.photos?.slice(0, 4)?.map((photo: any, index: number) => {
                                                        if (photo.image && photo.image.name && photo.image.type) {
                                                            return (
                                                                <div className="img-box rounded mr-3">
                                                                    <img src={configs.IMAGE_URL + "/clinic/" + photo.image.name + '-500x500.' + photo.image.type} alt="" />
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingFeatures &&
                                        <div>
                                            <h3>Preferences & Highlights</h3>
                                            <div className="d-flex highlights flex-wrap">
                                                <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                    <img src={require("./images/p1.svg")} alt="" className="mr-3" />
                                                    <small>Accepting new patients</small>
                                                </div>
                                                <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                    <img src={require("./images/p2.svg")} alt="" className="mr-3" />
                                                    <small>Available for weekend</small>
                                                </div>
                                                <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                    <img src={require("./images/p3.svg")} alt="" className="mr-3" />
                                                    <small>Insurance Accepted All or selected</small>
                                                </div>
                                                <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                    <img src={require("./images/p1.svg")} alt="" className="mr-3" />
                                                    <small>Social assistance programs accepted</small>
                                                </div>
                                                <div className="highlight-box d-flex p-3 rounded border align-items-center">
                                                    <img src={require("./images/p1.svg")} alt="" className="mr-3" />
                                                    <small>Financing plans available</small>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingProviders &&
                                        <div id="tab-providers">
                                            <h3>Providers</h3>
                                            {
                                                clinicProfile?.doctors.map((doctor: any, index: number) => {
                                                    let coreSpeciality = '';
                                                    if (doctor.specialities) {
                                                        const coreSpecialityFound = doctor.specialities.findIndex((speciality: any) => {
                                                            return speciality.coreSpeciality === "1";
                                                        });
                                                        if (coreSpecialityFound !== -1) {
                                                            coreSpeciality = doctor.specialities[coreSpecialityFound].name;
                                                        } else {
                                                            const specialityFound = doctor.specialities.findIndex((speciality: any) => {
                                                                return speciality.coreSpeciality !== "1";
                                                            });
                                                            coreSpeciality = doctor.specialities[specialityFound].name;
                                                        }
                                                    }
                                                    let firstField = '';
                                                    let secondField = '';
                                                    if (clinicProfile?.address) {
                                                        if (clinicProfile?.address.sublocality && clinicProfile?.address.sublocality !== "") {
                                                            firstField = clinicProfile?.address.sublocality;
                                                        } else if (clinicProfile?.address.neigborhood && clinicProfile?.address.neigborhood !== "") {
                                                            firstField = clinicProfile?.address.neigborhood;
                                                        } else if (clinicProfile?.address.locality && clinicProfile?.address.locality !== "") {
                                                            firstField = clinicProfile?.address.locality;
                                                        }

                                                        if (clinicProfile?.address.address_level_1_short && clinicProfile?.address.address_level_1_short !== "") {
                                                            secondField = clinicProfile?.address.address_level_1_short;
                                                        }
                                                    }
                                                    let doctorName = doctor.fullname.replace("Dr.", "");
                                                    doctorName = doctorName.trim();
                                                    doctorName = doctorName.split(" ");
                                                    const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                    const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                    return (
                                                        <div
                                                            className="practice-list-desc d-flex rounded p-3 align-items-center justify-content-between mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="img-box mr-2">
                                                                    {
                                                                        (doctor.photo && doctor.photo.name && doctor.photo.type) ?
                                                                            <img src={configs.IMAGE_URL + '/doctor/' + doctor.photo.name + '-200x200.' + doctor.photo.type} /> : <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <h4>{doctor?.fullname ?? ""}</h4>
                                                                    <div className="small d-flex ">
                                                                        <span className="pr-2 border-right mr-2">{coreSpeciality}</span>
                                                                        <div className="trusted-txt text-primary">
                                                                            <img src={require("./images/trusted-icon.svg")} className="mr-2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <NavLink to={"/" + doctor.fullname.trim().toLowerCase().replace(/[,._+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"} className="btn btn-primary btn-sm mr-3">
                                                                    Book now
                                                                </NavLink>
                                                                <NavLink to={"/" + doctor.fullname.trim().toLowerCase().replace(/[,._+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"} className="btn btn-dark btn-sm">
                                                                    View profile
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        reviews?.reviews?.length > 0 && clinicProfile?.onlineSchedulingReview &&
                                        <div id="tab-reviews">
                                            <div className="d-flex pb-3 border-bottom justify-content-between align-items-center">
                                                <div>
                                                    <h3 className="mb-1">Patient reviews</h3>
                                                    <p className="m-0">Read About Other Patients' Experiences</p>
                                                </div>
                                                <div className="review-detail-box px-md-3 px-2 py-md-2 py-1">
                                                    <div className="avg-rating">
                                                        <strong>{reviews?.rating?.avg}</strong>/5 <span className="ml-1 static-txt">Avg. Rating</span>
                                                    </div>
                                                    <div className="d-flex mt-1 align-items-center">
                                                        <RatingComp rating={reviews?.rating?.avg ?? 0}></RatingComp>
                                                        <span className="no-of-review ml-2">{reviews?.rating?.total} Reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                reviews?.reviews?.map((item: any, index: number) => {
                                                    return (
                                                        <div className="review-box-nw d-flex my-3" key={index + "review"}>
                                                            <div className="img-box mr-3">
                                                                <img src={configs.DEFAULT_IMAGE_URL_CLINIC} alt="" />
                                                            </div>
                                                            <div className="pb-3 border-bottom">
                                                                <RatingComp rating={Number(item.rating)}></RatingComp>
                                                                <div>{item.reviewText}</div>
                                                                <div className="date-review small">{moment(item.date).format("MMM. DD, YYYY")}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        clinicProfile?.onlineSchedulingFeatures &&
                                        <div id="tab-features">
                                            <h3>Features</h3>
                                            <ul className="d-flex flex-wrap">
                                                {
                                                    clinicProfile?.features?.map((feature: any, index: number) => (
                                                        <li key={index + "feature"}>{feature?.name}</li>
                                                    ))
                                                }
                                            </ul>
                                           
                                        </div>
                                    }
                                    {clinicProfile?.onlineSchedulingServices && <div id="tab-services">
                                        <h3>Services</h3>
                                        <ul className="d-flex flex-wrap">
                                            {
                                                services?.map((service, index) => (
                                                    <li key={index + "service"}>{service.name}</li>
                                                ))
                                            }
                                        </ul>
                                       
                                    </div>}
                                </div> */}
              </div>
              <div className="col-12 mt-5">
                {clinicProfile?.onlineSchedulingFeatures && (
                  <div id="tab-highlights" className="border-bottom tab-box">
                    <h4>Preferences & Highlights</h4>
                    <div className="row highlights">
                      {clinicProfile?.highlights.map(
                        (item: any, index: any) => {
                          return (
                            <div
                              className="col-xl-4 col-lg-6 col-md-4 col-6 my-2 px-2"
                              key={index}
                            >
                              <div className="highlight-box d-flex p-2  rounded border align-items-center">
                                <div className="img-box me-3 text-center">
                                  <img
                                    src={require("../../assets/images/accepting-new-patients.svg")}
                                    // src={configs.IMAGE_URL + item.image}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <small>{item.title}</small>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {clinicProfile?.onlineSchedulingProviders && (
                  <div id="tab-providers" className="border-bottom tab-box">
                    <ul className="two-col-grid">
                      {clinicProfile?.doctors.map(
                        (doctor: any, index: number) => {
                          let coreSpeciality = "";
                          if (doctor.specialities) {
                            const coreSpecialityFound =
                              doctor.specialities.findIndex(
                                (speciality: any) => {
                                  return speciality.coreSpeciality === "1";
                                }
                              );
                            if (coreSpecialityFound !== -1) {
                              coreSpeciality =
                                doctor.specialities[coreSpecialityFound].name;
                            } else {
                              const specialityFound =
                                doctor.specialities.findIndex(
                                  (speciality: any) => {
                                    return speciality.coreSpeciality !== "1";
                                  }
                                );
                              coreSpeciality =
                                doctor.specialities[specialityFound].name;
                            }
                          }
                          let firstField = "";
                          let secondField = "";
                          if (clinicProfile?.address) {
                            if (
                              clinicProfile?.address.sublocality &&
                              clinicProfile?.address.sublocality !== ""
                            ) {
                              firstField = clinicProfile?.address.sublocality;
                            } else if (
                              clinicProfile?.address.neigborhood &&
                              clinicProfile?.address.neigborhood !== ""
                            ) {
                              firstField = clinicProfile?.address.neigborhood;
                            } else if (
                              clinicProfile?.address.locality &&
                              clinicProfile?.address.locality !== ""
                            ) {
                              firstField = clinicProfile?.address.locality;
                            }

                            if (
                              clinicProfile?.address.address_level_1_short &&
                              clinicProfile?.address.address_level_1_short !==
                                ""
                            ) {
                              secondField =
                                clinicProfile?.address.address_level_1_short;
                            }
                          }
                          let doctorName = doctor.fullname.replace("Dr.", "");
                          doctorName = doctorName.trim();
                          doctorName = doctorName.split(" ");
                          const letterOne = doctorName[0]
                            ? doctorName[0].charAt(0)
                            : "";
                          const letterTwo = doctorName[1]
                            ? doctorName[1].charAt(0)
                            : "";
                          return (
                            <li>
                              <div className="provider-info">
                                <div className="img-box me-3">
                                  {doctor.photo &&
                                  doctor.photo.name &&
                                  doctor.photo.type ? (
                                    <img
                                      src={
                                        configs.IMAGE_URL +
                                        "/doctor/" +
                                        doctor.photo.name +
                                        "-200x200." +
                                        doctor.photo.type
                                      }
                                    />
                                  ) : (
                                    <Avatar>
                                      {letterOne}
                                      {letterTwo}
                                    </Avatar>
                                  )}
                                </div>
                                <div className="content-box">
                                  <h4>
                                    {doctor?.fullname ?? ""}{" "}
                                    <i className="bi bi-patch-check-fill ms-1 text-primary"></i>
                                  </h4>
                                  <small className="text-muted">
                                    {coreSpeciality}
                                  </small>
                                </div>
                              </div>
                              <button
                                // to={
                                //   "/" +
                                //   doctor.fullname
                                //     .trim()
                                //     .toLowerCase()
                                //     .replace(/[,._+]/g, "")
                                //     .replace(/\s+/g, "-") +
                                //   "/" +
                                //   doctor.providerId +
                                //   "/d"
                                // }
                                onClick={() => {
                                  history.push({
                                    pathname:
                                      "/" +
                                      doctor.fullname
                                        .trim()
                                        .toLowerCase()
                                        .replace(/[,._+]/g, "")
                                        .replace(/\s+/g, "-") +
                                      "/" +
                                      doctor.providerId +
                                      "/d",
                                    state: {
                                      isInternal: true,
                                    },
                                  });
                                }}
                                className="btn btn-dark mx-auto mx-md-0"
                              >
                                Book now
                              </button>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                )}
                {clinicProfile?.onlineSchedulingFeatures && (
                  <div
                    id="tab-features"
                    className="border-bottom tab-box list-box"
                  >
                    <h4>Features</h4>
                    <ul className="d-flex justify-content-between flex-wrap">
                      {featureExpanded
                        ? clinicProfile?.features?.map(
                            (feature: any, index: number) => (
                              <li key={index + "feature"}>{feature?.name}</li>
                            )
                          )
                        : clinicProfile?.features
                            ?.slice(0, 10)
                            .map((feature: any, index: number) => (
                              <li key={index + "feature"}>{feature?.name}</li>
                            ))}
                    </ul>
                    {!featureExpanded && (
                      <a
                        onClick={() => {
                          setFeatureExpanded(true);
                        }}
                        className="btn btn-link text-decoration-none mt-3 p-0"
                      >
                        View more +
                      </a>
                    )}
                  </div>
                )}
                {clinicProfile?.onlineSchedulingServices && (
                  <div
                    id="tab-services   "
                    className="border-bottom tab-box list-box"
                  >
                    <h4>Services</h4>
                    <ul className="d-flex justify-content-between flex-wrap">
                      {serviceExpanded
                        ? services?.map((service, index) => (
                            <li key={index + "service"}>{service.name}</li>
                          ))
                        : services
                            ?.slice(0, 10)
                            .map((service, index) => (
                              <li key={index + "service"}>{service.name}</li>
                            ))}
                    </ul>
                    {!serviceExpanded && (
                      <a
                        onClick={() => {
                          setServiceExpanded(true);
                        }}
                        className="btn btn-link text-decoration-none mt-3 p-0"
                      >
                        View more +
                      </a>
                    )}
                  </div>
                )}
                <div id="tab-reviews">
                  {clinicProfile?.onlineSchedulingReview && showReviews && (
                    <div id="tab-reviews" className="tab-box">
                      <div className="d-flex pb-3 justify-content-between">
                        <div>
                          <h4 className="mb-1">Patient reviews</h4>
                          <p className="m-0">
                            Read About Other Patients' Experiences
                          </p>
                        </div>
                        <div className="review-detail-box text-center">
                          <div className="avg-rating">
                            <strong>{reviews?.rating?.avg}</strong>/5
                          </div>
                          <RatingComp
                            rating={reviews?.rating?.avg ?? 0}
                          ></RatingComp>
                          <span className="no-of-review d-block">
                            {reviews?.rating?.total} Reviews
                          </span>
                        </div>
                      </div>
                      <div className="review-box-grid">
                        {reviewExpanded
                          ? reviews?.reviews?.map(
                              (item: any, index: number) => {
                                return (
                                  <div
                                    className="review-box-nw my-3"
                                    key={index + "review"}
                                  >
                                    <RatingComp
                                      rating={Number(item.rating)}
                                    ></RatingComp>
                                    <p>{item.reviewText}</p>
                                    <div className="date-review small">
                                      {moment(item.date).format(
                                        "MMM. DD, YYYY"
                                      )}
                                    </div>
                                    <div className="date-review small">
                                      {item.FullName}
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : reviews?.reviews
                              ?.slice(0, 5)
                              .map((item: any, index: number) => {
                                return (
                                  <div
                                    className="review-box-nw my-3"
                                    key={index + "review"}
                                  >
                                    <RatingComp
                                      rating={Number(item.rating)}
                                    ></RatingComp>
                                    <p>{item.reviewText}</p>
                                    <div className="date-review small">
                                      {moment(item.date).format(
                                        "MMM. DD, YYYY"
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                      </div>

                      {!reviewExpanded && (
                        <div className="text-center">
                          <a
                            onClick={() => {
                              setReviewExpanded(true);
                            }}
                            className="btn btn-dark mt-3 btn-lg"
                          >
                            See more reviews
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {isShowHeader && (
        <footer className="border-top py-3 text-end new-footer ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                © {moment().year()} Powered by XcarePro
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
