import axios from 'axios';
import apiConfigs from '../configs/apiConfigs';
export const submitDoctorForm = (request: any) => {
    return axios.post(apiConfigs.API_URL + 'inquiry/', request).then(response => ({ response }), error => ({ error }));
}
export const fetchOtherInsuranceProvider = () => {
    return axios.get(apiConfigs.API_URL + 'insurances/').then(response => ({ response }), error => ({ error }));
}
export const fetchResult = (request: any) => {
    console.log(request);
    // return true;
    return axios.post(apiConfigs.API_URL + 'results/', request, {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const fetchClinicProfile = (uniqueId: any) => {
    return axios.get(apiConfigs.API_URL + 'clinic/' + uniqueId + '/search/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const fetchDoctorProfile = (uniqueId: any) => {
    return axios.get(apiConfigs.API_URL + 'doctor/' + uniqueId + '/search/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const registerUser = (request: any) => {
    return axios.post(apiConfigs.API_URL + 'patient/register/', request, {
        headers: {
            xprApp: 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const verifyCode = (request: any) => {
    const id = request.id;
    delete request.id;
    return axios.post(apiConfigs.API_URL + 'patient/register/' + id + '/', request, {
        headers: {
            xprApp: 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const fetchConsultationType = (uniqueId: any, query = "") => {
    return axios.get(apiConfigs.API_URL + 'clinic/' + uniqueId + '/getconsultationtype/?' + query, {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const fetchProviderByConsultationType = (uniqueId: any, query = "") => {
    return axios.get(apiConfigs.API_URL + 'clinic/' + uniqueId + '/getconsultationtype/?' + query, {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const fetchConsultationsSlots = (data: any) => {
    return axios.post(apiConfigs.API_URL + 'appointmentSlotClinic/', data, {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const fetchFirstAvailableConsultationsSlots = (data: any) => {
    return axios.post(apiConfigs.API_URL + 'firstavailableslot/', data, {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}